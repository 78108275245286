import styled, {createGlobalStyle} from 'styled-components';

export const GlobalStylePage = createGlobalStyle`
  @media(min-width: 300px) and (max-width: 767px){
    .img-obra{
      width: 100%;
      height: 100%;
    }
    .speech-bubble-mini{
      margin-top: -270px;
      margin-left: -180px;
    }
  }
  @media(min-width: 768px) and (max-width: 1023px){
    .img-obra{
      width: 570px;
      height: 100%;
    }
    .speech-bubble-mini{
      margin-top: -270px;
      margin-left: -350px;
    }
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    .img-obra{
      width: 786px;
      height: 100%;
    }
    .speech-bubble-mini{
      margin-top: -300px;
      margin-left: -460px;
    }
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    .img-obra{
      width: 400px;
      height: 115%;
    }
    .speech-bubble-mini{
      margin-top: -200px;
      margin-left: -270px;
    }
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    .img-obra{
      width: 620px;
      height: 976px;
    }
    .speech-bubble-mini{
      margin-top: -330px;
      margin-left: -380px;
    }
  }
  @media(min-width: 1920px){
    .img-obra{
      width: 720px;
      height: 1150px;
    }
    .speech-bubble-mini{
      margin-top: -330px;
      margin-left: -440px;
    }
  }
`
export const ContArrow = styled.div`
  width: 100%;
  height: 40px;
  z-index: 999;
`
export const ContImgBailarina = styled.div`
  position: absolute;
  z-index: 99;

  @media(min-width: 300px) and (max-width: 767px){
    width: 80px;
    height: auto;
    left: 50%;
    margin-top: 98px;
    margin-left: 30px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 140px;
    height: auto;
    left: 50%;
    margin-top: 220px;
    margin-left: 100px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 220px;
    height: auto;
    left: 50%;
    margin-top: 215px;
    margin-left: 120px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 100px;
    height: auto;
    left: 50%;
    margin-top: 215px;
    margin-left: 50px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 150px;
    height: auto;
    left: 50%;
    margin-top: 250px;
    margin-left: 120px;
  }
  @media(min-width: 1920px){
    width: 180px;
    height: auto;
    left: 50%;
    margin-top: 180px;
    margin-left: 130px;
  }
`
export const ImgBailarina = styled.img`
  width: 100%;
  height: 100%;
`
export const ContImgCapitan = styled.div`
  position: absolute;
  z-index: 99;

  @media(min-width: 300px) and (max-width: 767px){
    width: 120px;
    height: auto;
    left: 50%;
    margin-top: -100px;
    margin-left: -95px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 200px;
    height: auto;
    left: 50%;
    margin-top: -170px;
    margin-left: -175px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 250px;
    height: auto;
    left: 50%;
    margin-top: -240px;
    margin-left: -245px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 150px;
    height: auto;
    left: 50%;
    margin-top: -135px;
    margin-left: -125px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 200px;
    height: auto;
    left: 50%;
    margin-top: -180px;
    margin-left: -192px;
  }
  @media(min-width: 1920px){
    width: 280px;
    height: auto;
    left: 50%;
    margin-top: -250px;
    margin-left: -250px;
  }
`
export const ImgCapitan = styled.img`
  width: 100%;
  height: 100%;
`
export const ContImgMujer = styled.div`
  position: absolute;
  z-index: 99;

  @media(min-width: 300px) and (max-width: 767px){
    width: 130px;
    height: auto;
    left: 50%;
    margin-top: 200px;
    margin-left: -189px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 240px;
    height: auto;
    left: 50%;
    margin-top: 400px;
    margin-left: -380px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 280px;
    height: auto;
    left: 50%;
    margin-top: 590px;
    margin-left: -490px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 180px;
    height: auto;
    left: 50%;
    margin-top: 295px;
    margin-left: -288px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 240px;
    height: auto;
    left: 50%;
    margin-top: 450px;
    margin-left: -382px;
  }
  @media(min-width: 1920px){
    width: 280px;
    height: auto;
    left: 50%;
    margin-top: 490px;
    margin-left: -430px;
  }
`
export const ImgMujer = styled.img`
  width: 100%;
  height: 100%;
`
export const ContImgHombreParado = styled.div`
  position: absolute;
  left: 50%;
  z-index: 99;

  @media(min-width: 300px) and (max-width: 767px){
    width: 70px;
    height: auto;
    margin-top: -150px;
    margin-left: -100px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 115px;
    height: auto;
    margin-top: -262px;
    margin-left: -220px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 150px;
    height: auto;
    margin-top: -348px;
    margin-left: -298px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 80px;
    height: auto;
    margin-top: -182px;
    margin-left: -145px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 110px;
    height: auto;
    margin-top: -260px;
    margin-left: -220px;
  }
  @media(min-width: 1920px){
    width: 130px;
    height: auto;
    margin-top: -305px;
    margin-left: -255px;
  }
`
export const ImgHombreParado = styled.img`
  width: 100%;
  height: 100%;
`
export const ContImgMujerMirando = styled.div`
  position: absolute;
  left: 50%;
  z-index: 99;

  @media(min-width: 300px) and (max-width: 767px){
    width: 65px;
    height: auto;
    margin-top: 86px;
    margin-left: -70px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 120px;
    height: auto;
    margin-top: 173px;
    margin-left: -98px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 160px;
    height: auto;
    margin-top: 232px;
    margin-left: -132px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 80px;
    height: auto;
    margin-top: 122px;
    margin-left: -63px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 120px;
    height: auto;
    margin-top: 190px;
    margin-left: -90px;
  }
  @media(min-width: 1920px){
    width: 150px;
    height: auto;
    margin-top: 215px;
    margin-left: -120px;
  }
`
export const ImgMujerMirando = styled.img`
  width: 100%;
  height: 100%;
`
