import React, {useEffect, useState} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import {Column, Columns} from 'bloomer';
import {ContObraComp} from '../../ContObra';
import {
  GlobalStylePage,
  ContArrow,
  ContImgBailarina,
  ImgBailarina,
  ContImgCapitan,
  ImgCapitan,
  ContImgMujer,
  ImgMujer,
  ContImgHombreParado,
  ImgHombreParado,
  ContImgMujerMirando,
  ImgMujerMirando
} from './styles';
import {ArrowNavTop, ArrowNavRight, ArrowNavBottom, ArrowNavLeft} from '../../ArrowsNavigation';
import {ContImgLeft, ContImgRight, GlobalStyle} from '../../../styles/GlobalStyles';
import {gsap} from "gsap";
import useSound from 'use-sound';
import {SpeechBubbleMini} from '../../SpeechBubbles/SpeechBubbleMini';
import {SpeechBubble} from '../../SpeechBubbles';
import imgBailarina from '../../../images/obras/obra-12/bailarina.png';
import imgCapitan from '../../../images/obras/obra-12/capitan.png';
import imgMujer from '../../../images/obras/obra-12/mujer.png';
import imgHombreParado from '../../../images/obras/obra-12/hombre-parado.png';
import imgMujerMirando from '../../../images/obras/obra-12/mujer-mirando.png';
import imgLeft from '../../../images/obras/obra-11/obra.png';
import imgRight from '../../../images/obras/obra-13/obra.jpeg';
import soundMar from '../../../images/obras/obra-12/mar.mp3';
import {BtnReplay} from '../../BtnReplay';
import {BtnParrilla} from '../../BtnParrilla';

const arNav = {
  top: null,
  right: 13,
  bottom: null,
  left: 11,
};

const Page12Comp = () => {
  const [showArrows, setShowArrows] = useState(false);
  const [initSpeechBubbleMini, setInitSpeechBubbleMini] = useState(false);
  const [initSpeechBubble, setInitSpeechBubble] = useState(false);
  const [initAnimatedBailarina, setInitAnimatedBailarina] = useState(false);
  const [initAnimatedCapitan, setInitAnimatedCapitan] = useState(false);
  const [initAnimatedMujer, setInitAnimatedMujer] = useState(false);
  const [initAnimatedHombreParado, setInitAnimatedHombreParado] = useState(false);
  const [initAnimatedMujerMirando, setInitAnimatedMujerMirando] = useState(false);
  const [initSoundMar, setInitSoundMar] = useState(false);
  const [reLaunchAll, setReLaunchAll] = useState(false);

  /*const data = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "obras/obra-1/obra.jpeg"}) {
        childImageSharp {
          fixed(width: 340, height: 670) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);*/
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "obras/obra-12/obra.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 750, maxHeight: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const mouseOver = () => {
    setShowArrows(true);
  };
  const mouseLeaver = () => {
    setShowArrows(false);
  };

  const fnReLaunchAll = () => {
    fnReLaunchBailarina(0);
    fnReLaunchCapitan(0);
    fnReLaunchMujer(0);
    fnReLaunchHombreParado(0);
    fnReLaunchMujerMirando(0);
    setReLaunchAll(!reLaunchAll);
  }

  const fnReLaunchBailarina = (noReplay = 1) => {
    setInitAnimatedBailarina(false);
    setInitSoundMar(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedBailarina(true);
      }, 1000);
    }
  };
  const fnReLaunchCapitan = (noReplay = 1) => {
    setInitAnimatedCapitan(false);
    // setInitSoundCapitan(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedCapitan(true);
      }, 1000);
    }
  };
  const fnReLaunchMujer = (noReplay = 1) => {
    setInitAnimatedMujer(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedMujer(true);
      }, 1000);
    }
  };
  const fnReLaunchHombreParado = (noReplay = 1) => {
    setInitAnimatedHombreParado(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedHombreParado(true);
      }, 1000);
    }
  };
  const fnReLaunchMujerMirando = (noReplay = 1) => {
    setInitAnimatedMujerMirando(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedMujerMirando(true);
      }, 1000);
    }
  };

  const fnAnimateBailarina = () => {
    return (
      <ContImgBailarina className={'img-animated'} onClick={() => fnReLaunchBailarina()}>
        {
          initAnimatedBailarina &&
          <ImgBailarina
            id={'animate-bailarina'}
            className={'animate-bailarina'}
            src={imgBailarina}
            alt="Luna"
          />
        }
      </ContImgBailarina>
    )
  }
  const fnAnimateCapitan = () => {
    return (
      <ContImgCapitan className={'img-animated'} onClick={() => fnReLaunchCapitan()}>
        {
          initAnimatedCapitan &&
          <ImgCapitan
            id={'animate-capitan'}
            className={'animate-capitan'}
            src={imgCapitan}
            alt="Capitán"
          />
        }
      </ContImgCapitan>
    )
  }
  const fnAnimateMujer = () => {
    return (
      <ContImgMujer className={'img-animated'} onClick={() => fnReLaunchMujer()}>
        {
          initAnimatedMujer &&
          <ImgCapitan
            id={'animate-mujer'}
            className={'animate-mujer'}
            src={imgMujer}
            alt="Mujer"
          />
        }
      </ContImgMujer>
    )
  }
  const fnAnimateHombreParado = () => {
    return (
      <ContImgHombreParado className={'img-animated'} onClick={() => fnReLaunchHombreParado()}>
        {
          initAnimatedHombreParado &&
          <ImgHombreParado
            id={'animate-hombre-parado'}
            className={'animate-hombre-parado'}
            src={imgHombreParado}
            alt="Hombre Parado"
          />
        }
      </ContImgHombreParado>
    )
  }
  const fnAnimateMujerMirando = () => {
    return (
      <ContImgMujerMirando className={'img-animated'} onClick={() => fnReLaunchMujerMirando()}>
        {
          initAnimatedMujerMirando &&
          <ImgHombreParado
            id={'animate-mujer-mirando'}
            className={'animate-mujer-mirando'}
            src={imgMujerMirando}
            alt="Mujer Mirando"
          />
        }
      </ContImgMujerMirando>
    )
  }

  const fnSpeechBubbleMini = () => {
    return (
      <>
        {
          initSpeechBubbleMini &&
          <SpeechBubbleMini
            id={'speech-bubble-mini'}
            className={'speech-bubble-mini'}
            onClick={() => setInitSpeechBubble(true)}
          />
        }
      </>
    )
  }
  const fnSpeechBubble = () => {
    return (
      <>
        {
          initSpeechBubble &&
          <SpeechBubble
            id={'speech-bubble'}
            className={''}
            classClose={'close-top-left'}
            onClick={() => setInitSpeechBubble(false)}
          >
            <div>Y resplandor más intenso que la llamarada</div>
            <div>de una rama o un brasero, revuelve el espíritu mudo:</div>
            <div>sin viento, pero fuego pentecostal</div>
          </SpeechBubble>
        }
      </>
    )
  }

  const fnLeft = () => {
    return <ContImgLeft src={imgLeft} />
  }
  const fnRight = () => {
    return <ContImgRight src={imgRight} />
  }

  const [playMar, { stopMar }] = useSound(
    soundMar,
    { volume: 0.1 }
  );

  useEffect(() => {
    setTimeout(function (){
      setInitAnimatedBailarina(true);
    }, 3000);

    setTimeout(function (){
      setInitAnimatedCapitan(true);
    }, 6000);

    setTimeout(function (){
      setInitAnimatedMujer(true);
    }, 9000);

    setTimeout(function (){
      setInitAnimatedHombreParado(true);
    }, 12000);

    setTimeout(function (){
      setInitAnimatedMujerMirando(true);
    }, 15000);

    setTimeout(function (){
      setInitSpeechBubbleMini(true);
    }, 18000);
  },[reLaunchAll]);

  useEffect(() => {
    const windowWrap = gsap.utils.wrap(0, window.innerWidth);
    const winWidth = window.innerWidth;
    let arPosBailarina = {};
    let arPosCapitan = {};

    if(winWidth < 767){
      arPosBailarina = {y: 130}
      arPosCapitan = {x: 100}
    } else if(winWidth < 1023){
      arPosBailarina = {y: 200}
      arPosCapitan = {x: 215}
    } else if(winWidth < 1215){
      arPosBailarina = {y: 300}
      arPosCapitan = {x: 315}
    } else if(winWidth < 1407){
      arPosBailarina = {y: 100}
      arPosCapitan = {x: 150}
    } else if(winWidth < 1919){
      arPosBailarina = {y: 200}
      arPosCapitan = {x: 230}
    } else {
      arPosBailarina = {y: 300}
      arPosCapitan = {x: 250}
    }

    if(initAnimatedBailarina){
      gsap.to(".animate-bailarina", {
        y: arPosBailarina.y,
        modifiers: {
          y: y => windowWrap(parseFloat(y)) + "px"
        },
        rotation: function(i, target, list) {
          return -143;
        },
      });

      if(!initSoundMar){
        playMar();
        setInitSoundMar(true);
      }
    }

    if(initAnimatedCapitan){
      gsap.to(".animate-capitan", {
        x: arPosCapitan.x,
        modifiers: {
          x: x => windowWrap(parseFloat(x)) + "px"
        },
        rotation: function(i, target, list) {
          return 33;
        },
      });
    }

    if(initAnimatedMujer){
      gsap.to(".animate-mujer", {
        opacity: 1,
      });
    }

    if(initAnimatedHombreParado){
      gsap.to(".animate-hombre-parado", {
        opacity: 1,
      });
    }

    if(initAnimatedMujerMirando){
      gsap.to(".animate-mujer-mirando", {
        opacity: 1,
      });
    }

  },[initAnimatedBailarina, initAnimatedCapitan, initAnimatedMujer, initAnimatedHombreParado, initAnimatedMujerMirando]);


  return (
    <ContObraComp>
      <BtnParrilla/>
      <BtnReplay onClick={() => fnReLaunchAll()}/>
      <div className={'columns-padding-no cont-obra-height'} onMouseOver={mouseOver} onMouseLeave={mouseLeaver}>
        <GlobalStyle/>
        <GlobalStylePage/>

        <Columns className="columns-responsive" isMultiline={false} isCentered={true}>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavTop toPage={arNav.top}/>}
            </ContArrow>
          </Column>
        </Columns>

        <Columns className="columns-responsive columns-obra-height flex-center" isMultiline={false} isCentered={true}>

          <Column isSize={{mobile: 1, tablet: 1, desktop: 1, widescreen: 1}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavLeft toPage={arNav.left}/>}
            </ContArrow>
          </Column>

          <Column isSize={{mobile: 10, tablet: 10, desktop: 10, widescreen: 10}} className={'flex-center'}>
            {/*<Img fixed={data.file.childImageSharp.fixed} />*/}
            <Img className={'img-obra'} fluid={data.placeholderImage.childImageSharp.fluid}/>
            {fnAnimateBailarina()}
            {fnAnimateCapitan()}
            {fnAnimateMujer()}
            {fnAnimateHombreParado()}
            {fnAnimateMujerMirando()}
            {fnSpeechBubbleMini()}
            {fnSpeechBubble()}
            {fnLeft()}
            {fnRight()}
          </Column>

          <Column isSize={{mobile: 1, tablet: 1, desktop: 1, widescreen: 1}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavRight toPage={arNav.right}/>}
            </ContArrow>
          </Column>

        </Columns>

        <Columns className="columns-responsive" isMultiline={false} isCentered={true}>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavBottom toPage={arNav.bottom}/>}
            </ContArrow>
          </Column>
        </Columns>
      </div>
    </ContObraComp>
  );
};

export default Page12Comp;
